import React from "react";
import FieldWithErrors from "../FieldWithErrors";
import Modal from "./ui/Modal/Modal";
import { useFieldArray } from "react-final-form-arrays";

export default function PromosModal(props) {
  const required = value => (value ? undefined : "Required");
  const { fields: promos } = useFieldArray("promos", {
    subscription: { submitting: true, pristine: true, value: true }
  });

  const { onClose, open, promoForEditing } = props;
  const index = promoForEditing === null ? promos.length - 1 : promoForEditing;

  return (
    <Modal modalClose={onClose} show={open}>
      <h2>Add a Voucher</h2>
      <div style={{ margin: "0 0.5em" }}>
        <div
          style={{
            padding: "0.5em 0",
            fontWeight: "bold"
          }}
        >
          Voucher Value
        </div>
        <FieldWithErrors
          name={`promos[${index}].value`}
          component="input"
          type="text"
          validate={required}
          placeholder="00,00 €"
        />
      </div>

      <div style={{ margin: "0 0.5em" }}>
        <div
          style={{
            margin: "auto",
            padding: "0.5em 0",
            fontWeight: "bold"
          }}
        >
          Selling Price
        </div>
        <FieldWithErrors
          name={`promos[${index}].price`}
          component="input"
          type="text"
          validate={required}
          placeholder="00,00 €"
        />
      </div>

      <div style={{ margin: "0 0.5em" }}>
        <div
          style={{
            margin: "auto",
            padding: "0.5em 0",
            fontWeight: "bold"
          }}
        >
          Amount Available
        </div>
        <FieldWithErrors
          name={`promos[${index}].maxVouchers`}
          component="input"
          type="text"
          validate={required}
          placeholder="100"
        />
      </div>

      <div className="promos-modal__submit">
        <button onClick={onClose} type="button">
          Create Voucher
        </button>
      </div>
    </Modal>
  );
}
