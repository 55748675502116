import React from "react";
import { Helmet } from "react-helmet";

export default function MetaHeaders(props) {
  const campaign = props.campaign;

  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>{campaign.title}</title>
      <meta name="description" content={campaign.description} />
      {/* <link rel="canonical" href="http://mysite.com/example" /> */}
    </Helmet>
  );
}
