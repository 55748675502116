import React, { useState } from "react";
import HowItWorks from "./shared/HowItWorks";
import MetaHeaders from "./shared/MetaHeaders";
import { useTranslation } from 'react-i18next';
import Footer from "./shared/Footer";
import LaunchModal from './LaunchModal';

const API_HOST =
  process.env.NODE_ENV === "production" ? "" : "http://localhost:3001";

const formattedDate = date =>
  new Date(date).toLocaleDateString("en-US", {
    day: "numeric",
    month: "short",
    year: "numeric"
  });

function ShowCampaign({ isLaunchStep, uuid, ...props }) {
  const campaign = props.campaignData;
  const { t } = useTranslation();
  const [launchModalOpen, setLaunchModalOpen] = useState(true);

  const onClose = () => setLaunchModalOpen(false);

  const secondaryFooterLink = <a
    style={{
      marginLeft: "32px",
      color: "white",
      fontWeight: "bold",
      textDecoration: "none"
    }}
    href={`/new-campaign/${uuid}`}
  >
    {"< Edit Voucher Campaign"}
  </a>;

  return (
    <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", position: 'relative' }}>
      <MetaHeaders campaign={campaign}/>

      <header>
        <div className="title">
          <h1>{campaign.title}</h1>
          <p>{campaign.subtitle}</p>
        </div>

        <div className="img">
          <img src={campaign.heroImageUrl} alt="company"/>
        </div>

        <div className="promos">
          <div className="promos__list">
            <h4>Support local business</h4>
            {campaign.promos.map(promo => (
              <div key={promo.id}>
                <label htmlFor="value">
                  <input
                    type="radio"
                    id={promo.value}
                    name="value"
                    value={promo.value}
                  />
                  <span>
                    <b>{Number.parseFloat(promo.value).toFixed(0)}€</b> for the
                    price of {Number.parseFloat(promo.price).toFixed(0)}€
                  </span>
                </label>
              </div>
            ))}
            <button
              style={{
                margin: "0 auto",
                display: "block",
                width: "85%"
              }}
            >
              Buy Voucher
            </button>
          </div>
        </div>
      </header>

      {isLaunchStep && <LaunchModal
        dashboardLink={'#'}
        url={'pass actual url here'}
        open={launchModalOpen}
        onClose={onClose}
      />}

      <section className="main" style={{ marginBottom: '2em' }}>
        <h2>{t('teamMessage')}</h2>
        <p style={{ whiteSpace: "pre-wrap" }}>{campaign.description}</p>

        <div className="owner preview">
          <img src={campaign.managerPhotoUrl} alt="company's owner"/>
          <span>{campaign.managerName}</span>
        </div>

        <h2>You are supporting</h2>
        <ul className="supporting">
          {campaign.bulletPoints.map((bp, i) => (
            <li key={i}>{bp}</li>
          ))}
        </ul>

        <h2>Visit us</h2>
        <p style={{ whiteSpace: "pre-wrap" }}>{campaign.address}</p>
        <p>
          {campaign.phone ? (
            <a href={`tel:${campaign.phone}`}>{campaign.phone}</a>
          ) : null}
        </p>
        <p>
          {campaign.website ? (
            <a href={campaign.website}>{campaign.website}</a>
          ) : null}
        </p>
        <p>
          {campaign.facebook ? (
            <a href={`https://facebook.com/${campaign.facebook}`}>
              {campaign.facebook}
            </a>
          ) : null}
        </p>
        <p>
          {campaign.instagram ? (
            <a href={`https://instagram.com/${campaign.instagram}`}>
              @{campaign.instagram}
            </a>
          ) : null}
        </p>
      </section>

      <section className="sidebar" style={{ marginBottom: '2em' }}>
        <HowItWorks/>

        <h2>Terms & Conditions</h2>
        <ul>
          <li>Use by {formattedDate(campaign.timeframe)}</li>
          {campaign.ownTerms.map((ot, index) => (
            <li key={index}>{ot}</li>
          ))}
        </ul>
      </section>
      {!isLaunchStep && <Footer secondaryLink={secondaryFooterLink}>
        <a
          className="button"
          style={{
            'marginRight': '32px',
            'height': '35px',
            'padding': '0.4em 1.5em'
          }}
          href={`${API_HOST}/merchants/auth/stripe?uuid=${props.uuid}`}
        >
          {t('connectPayment')}
        </a>
      </Footer>}
    </div>
  );
}

export default ShowCampaign;
