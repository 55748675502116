import React from 'react';
import { withRouter } from 'react-router-dom';

import classes from './WizardSteps.module.css';
import SingleStep from "./SingleStep";

function WizardSteps({location}) {

  return (
    <div className={classes.Container}>
      <SingleStep
        stepLabel={"Create Voucher"}
        stepNumber={1}
        isFirst={true}
        currentRoute={location.pathname}
      />
      <SingleStep
        stepLabel={'Preview'}
        stepNumber={2}
        currentRoute={location.pathname}
      />
      <SingleStep
        stepLabel={'Payment'}
        stepNumber={3}
        currentRoute={location.pathname}
      />
      <SingleStep
        stepLabel={'Launch & Share'}
        stepNumber={4}
        isLast={true}
        currentRoute={location.pathname}
      />


    </div>
  );
}

export default withRouter(WizardSteps);
