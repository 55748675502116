import React, { Component, Fragment } from "react";
import MetaHeaders from "./shared/MetaHeaders";
import FormForAPI from "../FormForAPI";
import FieldWithErrors from "../FieldWithErrors";
import { Field, FormSpy } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import { FORM_ERROR } from "final-form";
import ManagerPhoto from "./ManagerPhoto";
import Sidebar from "./Sidebar";
import Header from "./Header";
import PromosModal from "./PromosModal";
import { withTranslation } from 'react-i18next';
import Footer from "./shared/Footer";

class EditCampaign extends Component {
  state = {
    promoFormOpen: false,
    promoForEditing: null
  };

  openPromoModalForEditing = index => {
    this.setState({
      promoFormOpen: true,
      promoForEditing: index
    });
  };

  closePromoModal = () => {
    this.setState({
      promoFormOpen: false,
      promoForEditing: null
    });
  };


  render() {
    const campaign = this.props.campaignData;
    const { t } = this.props;

    const required = value => (value ? undefined : "Required");
    const secondaryFooterLink = <a
        style={{
          marginLeft: "32px",
          color: "white",
          fontWeight: "bold",
          textDecoration: "none"
        }}
        href='https://docs.vouchery.io/docs/get-help-using-voucheraid'
        target={"_blank"}
        rel="noopener noreferrer"
      >
        Get Help
      </a>;

    return (
      <Fragment>
        <MetaHeaders
          campaign={{
            title: "Voucher Aid | New Campaign",
            description:
              "Boost your cash-flow during tough times. Sell discounted vouchers valid in the future. Completely free."
          }}
        />
        <FormForAPI
          onSubmit={this.props.onSubmit}
          initialValues={campaign}
          validate={values =>
            values.promos.length === 0
              ? { [FORM_ERROR]: "You must add at least one promo" }
              : undefined
          }
          children={form => (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                marginBottom: "3em",
                position: "relative"
              }}
            >
              <Field
                name="uuid"
                component="input"
                type="hidden"
                initialValue={this.props.uuid}
              />

              <Header
                uuid={this.props.uuid}
                openPromoModalForEditing={this.openPromoModalForEditing}
              />

              <section
                className="main"
                style={{ display: "flex", flexDirection: "column" }}
              >
                <h2>{t('teamMessage')}</h2>
                <FieldWithErrors
                  name="description"
                  component="textarea"
                  type="text"
                  validate={required}
                  rows={7}
                  placeholder="Here you have space to tell the community about your business, why it's important to support, and how these contributions will be used."
                />

                <div className="owner">
                  <ManagerPhoto uuid={this.props.uuid}/>

                  <FieldWithErrors
                    name="managerName"
                    component="input"
                    type="text"
                    validate={required}
                    style={{ width: "80%", margin: "0.6em 0" }}
                    placeholder="Add your name and photo for friendly touch!"
                  />
                </div>

                <h2>You are supporting</h2>
                <div className="supporting">
                  <FieldArray
                    name="bulletPoints"
                    subscription={{ submitting: true, pristine: true }}
                  >
                    {({ fields }) => {
                      return (
                        <div className="input-array">
                          {fields.map((name, index) => (
                            <div key={name} style={{ display: "flex" }}>
                              <FieldWithErrors
                                name={name}
                                component="input"
                                type="text"
                                validate={required}
                              />
                              {index ? (
                                <button
                                  type="button"
                                  onClick={() => fields.remove(index)}
                                  className="button--remove"
                                >
                                  &times;
                                </button>
                              ) : null}
                            </div>
                          ))}
                          {fields.length < 7 ? (
                            <button
                              type="button"
                              onClick={() => fields.push(" ")}
                            >
                              Add another reason
                            </button>
                          ) : null}
                        </div>
                      );
                    }}
                  </FieldArray>
                </div>

                <h2>Visit Us</h2>

                <FieldWithErrors
                  name="address"
                  component="textarea"
                  validate={required}
                  rows="5"
                  placeholder="Address"
                />

                <FieldWithErrors
                  name="phone"
                  component="input"
                  type="tel"
                  validate={required}
                  style={{ width: "50%" }}
                  placeholder="Phone"
                />

                <FieldWithErrors
                  name="website"
                  component="input"
                  type="text"
                  style={{ width: "50%" }}
                  placeholder="Website (optional)"
                />

                <FieldWithErrors
                  name="facebook"
                  component="input"
                  type="text"
                  style={{ width: "50%" }}
                  placeholder="Facebook (optional)"
                />

                <FieldWithErrors
                  name="instagram"
                  component="input"
                  type="text"
                  style={{ width: "50%", marginBottom: "0.5em" }}
                  placeholder="Instagram (optional)"
                />
                <Footer secondaryLink={secondaryFooterLink}>
                  <button
                    style={{
                      'marginRight': '32px',
                      'height': '35px',
                      'padding': '0.4em 1.5em'
                    }}
                    type="submit"
                  >
                    {t('showPreview')}
                  </button>
                </Footer>
              </section>

              <Sidebar/>

              <PromosModal
                open={this.state.promoFormOpen}
                onClose={this.closePromoModal}
                promoForEditing={this.state.promoForEditing}
              />

              <FormSpy
                subscription={{ values: true, pristine: true }}
                onChange={formProps =>
                  formProps.pristine
                    ? null
                    : this.props.onChange(formProps.values)
                }
              />
            </div>
          )}
        />{" "}
        {/* END FormForAPI */}

      </Fragment>
    );
  }
}

export default withTranslation()(EditCampaign);
