import React from 'react';
import PropTypes from 'prop-types';


import classes from './Footer.module.css';

function Footer({ secondaryLink, children }) {

  return (
    <div className={classes.Container}>

      {secondaryLink}
      {React.Children.only(children)}
    </div>
  );
}

Footer.propTypes = {
  nextStepLabel: PropTypes.string
};


export default Footer;
