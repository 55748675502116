import React, { useState } from "react";
import ReactS3Uploader from "react-s3-uploader";
import { Field, useForm } from "react-final-form";
import Loading from "../Loading";

const API_HOST =
  process.env.NODE_ENV === "production" ? "" : "http://localhost:3001";

export default function HeroImage(props) {
  const { uuid } = props;
  const [heroImageUploading, setHeroImageUploading] = useState(false);
  const [heroImageProgress, setHeroImageProgress] = useState(null);
  const form = useForm();

  return (
    <Field
      name="heroImageUrl"
      component="input"
      type="hidden"
      subscription={{
        value: true
      }}
    >
      {({ input: { value: heroImageUrl } }) => (
        <div
          className="img"
          style={{
            background: " center center / cover",
            backgroundImage: `url(${heroImageUrl})`
          }}
        >
          <ReactS3Uploader
            accept="image/*"
            signingUrl={`${API_HOST}/s3-signature`}
            signingUrlHeaders={{
              "Access-Control-Request-Headers": "Authorization",
              "Content-Type": "application/json",
              Accept: "application/json"
            }}
            uploadRequestHeaders={{}}
            onProgress={percent => {
              setHeroImageUploading(true);
              setHeroImageProgress(percent);
            }}
            onFinish={({ publicUrl }) => {
              setHeroImageUploading(false);
              setHeroImageProgress(null);
              form.mutators.setField("heroImageUrl", publicUrl);
            }}
            onError={() => {
              setHeroImageUploading(false);
              setHeroImageProgress(null);
            }}
            id="heroImageUrl"
            disabled={heroImageUploading}
            scrubFilename={fn => {
              const ext = [...fn.split(".")].pop();
              return `${uuid}-hero.${ext}`;
            }}
          />
          {heroImageUploading ? null : (
            <label htmlFor="heroImageUrl" className="button">
              {heroImageUrl ? "Change file" : "Select file"}
            </label>
          )}
          {heroImageUploading ? (
            <span style={{ padding: "0 2em" }}>
              <Loading /> Uploading... {heroImageProgress}%
            </span>
          ) : null}
        </div>
      )}
    </Field>
  );
}
