import React, { useCallback } from "react";
import PropTypes from 'prop-types';
import classNames from 'classnames';

import classes from "./WizardSteps.module.css";

// should be updated when the routes for the 3rd n 4th step are added
const stepMapping = {
  1: 'new-campaign',
  2: 'preview',
  4: 'launch'
};

function SingleStep({
  stepLabel,
  stepNumber,
  currentRoute,
  isFirst=false,
  isLast=false
}) {
  const isActive = useCallback(()=> {
    return stepMapping[stepNumber.toString()] === currentRoute.slice(1).split('/')[0];
  }, [stepNumber, currentRoute]);

  return <div className={classes.StepBoxContainer}>
    <section className={classes.StepBox}>
      {!isFirst && <span className={classes.ConnectorLeft}/>}
      <span
        className={classNames(classes.StepNumber, {[classes.StepNumberActive]: isActive()})}
      >
      {stepNumber}
    </span>
      {!isLast && <span className={classes.ConnectorRight}/>}

    </section>
    <label className={classes.StepLabel}>{stepLabel}</label>
  </div>;
}

SingleStep.propTypes = {
  stepLabel: PropTypes.string.isRequired,
  stepNumber: PropTypes.number.isRequired,
  currentRoute: PropTypes.string.isRequired, // location.pathname
  isFirst: PropTypes.bool,
  isLast: PropTypes.bool
};

export default SingleStep;
