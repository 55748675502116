import React from "react";
import { Field } from "react-final-form";
import FieldWithErrors from "../FieldWithErrors";
import HeroImage from "./HeroImage";
import { useFieldArray } from "react-final-form-arrays";

export default function Header(props) {
  const required = value => (value ? undefined : "Required");
  const { fields: promos } = useFieldArray("promos", {
    subscription: { submitting: true, pristine: true, value: true }
  });

  const { uuid, openPromoModalForEditing } = props;

  return (
    <header>
      <div
        className="title"
        style={{ display: "flex", flexDirection: "column" }}
      >
        <div style={{ margin: "0.67em 0" }}>
          <FieldWithErrors
            name="title"
            component="input"
            type="text"
            validate={required}
            placeholder="Add your campaign title"
          />
        </div>
        <FieldWithErrors
          name="subtitle"
          component="input"
          type="text"
          validate={required}
          placeholder="Write a 1 sentence intro to your campaign"
        />
      </div>

      <HeroImage uuid={uuid} />

      <div className="promos">
        <div className="promos__list">
          {promos.value.map((promo, i) => (
            <div key={i}>
              <label htmlFor="value">
                <input
                  type="radio"
                  id={promo.value}
                  name="value"
                  value={promo.value}
                />
                <span>
                  <b>{Number.parseFloat(promo.value).toFixed(0)}€</b> for the
                  price of {Number.parseFloat(promo.price).toFixed(0)}€
                </span>
              </label>
              <div className="button-container">
                <button
                  type="button"
                  onClick={() => promos.remove(i)}
                  className="button--remove promo"
                >
                  &times;
                </button>
                <button
                  type="button"
                  onClick={() => openPromoModalForEditing(i)}
                  className="button promo"
                >
                  Edit
                </button>
              </div>
              <Field
                name={`promos[${i}].value`}
                component="input"
                type="hidden"
                value={promo.value}
              />
              <Field
                name={`promos[${i}].price`}
                component="input"
                type="hidden"
                value={promo.price}
              />
              <Field
                name={`promos[${i}].maxVouchers`}
                component="input"
                type="hidden"
                value={promo.maxVouchers}
              />
            </div>
          ))}
          {promos.length < 4 ? (
            <button
              type="button"
              onClick={() => {
                promos.push({});
                openPromoModalForEditing(null);
              }}
              style={{ margin: "0 auto", display: "block" }}
            >
              Add a voucher
            </button>
          ) : null}
        </div>
      </div>
    </header>
  );
}
