import React from 'react'
import ShowCampaign from "./Show";
import Loading from "../Loading";
const API_HOST =
  process.env.NODE_ENV === "production" ? "" : "http://localhost:3001";

export default class CampaignLanding extends React.Component {
  state = {
    slug: this.props.match.params.slug
  };

  componentDidMount = async () => {
    const responseJSON = await fetch(
      `${API_HOST}/campaigns/${this.state.slug}`,
      {
        method: "GET",
        mode: "cors",
        headers: {
          "Access-Control-Request-Headers": "Authorization",
          "Content-Type": "application/json",
          Accept: "application/json"
        }
      }
    );
    const response = await responseJSON.json();
    console.log("loaded", response);
    this.setState({ campaignData: response });
  };
  edit = () => {}

  render() {
    return this.state.campaignData ? (
      <ShowCampaign {...this.props} {...this.state} onEdit={this.edit} />
    ) : (
      <div className="loading">
        <Loading size="8x" />
      </div>
    );
  }
}
