import React from "react";
import EditCampaign from "./Edit";
import ShowCampaign from "./Show";
import history from "../history";
import Loading from "../Loading";
const API_HOST =
  process.env.NODE_ENV === "production" ? "" : "http://localhost:3001";

// Credit David Walsh (https://davidwalsh.name/javascript-debounce-function)

// Returns a function, that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// N milliseconds. If `immediate` is passed, trigger the function on the
// leading edge, instead of the trailing.
function debounce(func, wait, immediate) {
  var timeout;

  // This is the function that is actually executed when
  // the DOM event is triggered.
  return function executedFunction() {
    // Store the context of this and any
    // parameters passed to executedFunction
    var context = this;
    var args = arguments;

    // The function to be called after
    // the debounce time has elapsed
    var later = function() {
      // null timeout to indicate the debounce ended
      timeout = null;

      // Call function now if you did not on the leading end
      if (!immediate) func.apply(context, args);
    };

    // Determine if you should call the function
    // on the leading or trail end
    var callNow = immediate && !timeout;

    // This will reset the waiting every function execution.
    // This is the step that prevents the function from
    // being executed because it will never reach the
    // inside of the previous setTimeout
    clearTimeout(timeout);

    // Restart the debounce waiting period.
    // setTimeout returns a truthy value (it differs in web vs node)
    timeout = setTimeout(later, wait);

    // Call immediately if you're dong a leading
    // end execution
    if (callNow) func.apply(context, args);
  };
}

export class EditCampaignContainer extends React.Component {
  /* Only props which require non-null default values */
  defaultCampaignData = {
    bulletPoints: [""],
    ownTerms: [],
    promos: []
  };

  state = {
    uuid: this.props.match.params.uuid
  };

  componentDidMount = async () => {
    const responseJSON = await fetch(
      `${API_HOST}/campaigns/${this.state.uuid}`,
      {
        method: "GET",
        mode: "cors",
        headers: {
          "Access-Control-Request-Headers": "Authorization",
          "Content-Type": "application/json",
          Accept: "application/json"
        }
      }
    );
    const response = await responseJSON.json();
    console.log("loaded", response);
    if (response) {
      this.setState({ campaignData: response });
    } else {
      this.setState({ campaignData: this.defaultCampaignData });
    }
  };

  update = debounce(values => this.submit(values), 300);

  preview = async campaignData => {
    await this.submit(campaignData);
    this.setState({ campaignData });
    history.push(`/preview/${this.state.uuid}`);
    return {};
  };

  submit = async campaignData => {
    const responseJSON = await fetch(`${API_HOST}/campaigns`, {
      method: "POST",
      mode: "cors",
      headers: {
        "Access-Control-Request-Headers": "Authorization",
        "Content-Type": "application/json",
        Accept: "application/json"
      },
      body: JSON.stringify({ campaign: campaignData })
    });
    const response = await responseJSON.json();
    console.log("submitted", campaignData, response);
    return {};
  };

  render() {
    return this.state.campaignData ? (
      <EditCampaign
        {...this.state}
        onChange={this.update}
        onSubmit={this.preview}
      />
    ) : (
      <div className="loading">
        <Loading size="8x" />
      </div>
    );
  }
}

export class PreviewCampaignContainer extends React.Component {
  state = {
    uuid: this.props.match.params.uuid
  };

  componentDidMount = async () => {
    console.log(this.props.isLaunchStep);
    const responseJSON = await fetch(
      `${API_HOST}/campaigns/${this.state.uuid}`,
      {
        method: "GET",
        mode: "cors",
        headers: {
          "Access-Control-Request-Headers": "Authorization",
          "Content-Type": "application/json",
          Accept: "application/json"
        }
      }
    );
    const response = await responseJSON.json();
    console.log("loaded", response);
    this.setState({ campaignData: response });
  };

  edit = () => {
    history.push(`/new-campaign/${this.state.uuid}`);
  };

  render() {
    return this.state.campaignData ? (
      <ShowCampaign {...this.props} {...this.state} onEdit={this.edit} />
    ) : (
      <div className="loading">
        <Loading size="8x" />
      </div>
    );
  }
}
