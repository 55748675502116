import React from "react";
import { Field } from "react-final-form";

export default function FieldWithErrors(props) {
  // Patch for empty values not being submitted https://github.com/final-form/react-final-form/issues/130#issuecomment-425482365
  const identity = value => value;

  return (
    <Field parse={identity} {...props}>
      {({ input, meta }) => (
        <div className="input-wrapper">
          {React.createElement(
            props.component,
            Object.assign(
              {},
              {
                style: props.style,
                rows: props.rows,
                placeholder: props.placeholder,
                className: props.className,
              },
              input
            ),
            props.children
          )}
          {(meta.error || meta.submitError) && meta.touched && (
            <div className="input__error">{meta.error || meta.submitError}</div>
          )}
        </div>
      )}
    </Field>
  );
}
