import React, { useState } from "react";
import ReactS3Uploader from "react-s3-uploader";
import { Field, useForm } from "react-final-form";
import Loading from "../Loading";

const API_HOST =
  process.env.NODE_ENV === "production" ? "" : "http://localhost:3001";

export default function ManagerPhoto(props) {
  const { uuid } = props;
  const [managerPhotoUploading, setManagerPhotoUploading] = useState(false);
  const form = useForm();

  return (
    <Field
      name="managerPhotoUrl"
      component="input"
      type="hidden"
      subscription={{
        value: true
      }}
    >
      {({ input: { value: managerPhotoUrl } }) => (
        <div
          className="img"
          style={{
            background: " center center / cover",
            backgroundImage: `url(${managerPhotoUrl})`
          }}
        >
          {managerPhotoUploading ? (
            <Loading
              style={{
                width: "50%",
                height: "50%",
                padding: "25% 25%",
                margin: "0 auto",
                textAlign: "center"
              }}
            />
          ) : (
            <label
              htmlFor="managerPhotoUrl"
              className="button-plus button--transparent"
            >
              {"+"}
            </label>
          )}
          <ReactS3Uploader
            accept="image/*"
            signingUrl={`${API_HOST}/s3-signature`}
            signingUrlHeaders={{
              "Access-Control-Request-Headers": "Authorization",
              "Content-Type": "application/json",
              Accept: "application/json"
            }}
            uploadRequestHeaders={{}}
            onProgress={percent => {
              setManagerPhotoUploading(true);
            }}
            onFinish={({ publicUrl }) => {
              setManagerPhotoUploading(false);
              form.mutators.setField("managerPhotoUrl", publicUrl);
            }}
            onError={() => {
              setManagerPhotoUploading(false);
            }}
            id="managerPhotoUrl"
            disabled={managerPhotoUploading}
            scrubFilename={fn => {
              const ext = [...fn.split(".")].pop();
              return `${uuid}-manager.${ext}`;
            }}
          />
        </div>
      )}
    </Field>
  );
}
