import React from "react";
import { Form } from "react-final-form";
import { FORM_ERROR } from "final-form";
import PropTypes from "prop-types";
import arrayMutators from "final-form-arrays";

function FormForAPI(props) {
  const { onSubmit, validate, initialValues } = props;

  const onSubmitWithErrors = async data => {
    let response = await onSubmit(data);

    if (response.error) {
      if (response.error) {
        return response.error.reduce((acc, error) => {
          acc[error.attribute] = error.message;
          return acc;
        }, {});
      } else {
        return { [FORM_ERROR]: response.message };
      }
    } else {
      if (props.onSuccess) {
        props.onSuccess();
      }
      return undefined;
    }
  };

  const setField = (field, value, state, changeValue) =>
    changeValue(state, field, () => value);

  const isFunction = value =>
    value &&
    (Object.prototype.toString.call(value) === "[object Function]" ||
      "function" === typeof value ||
      value instanceof Function);

  return (
    <Form
      onSubmit={onSubmitWithErrors}
      validate={validate}
      initialValues={initialValues}
      mutators={{
        setField: ([field, value], state, { changeValue }) =>
          setField(field, value, state, changeValue),
        ...arrayMutators
      }}
      render={({
        handleSubmit,
        error,
        submitError,
        form,
        submitFailed,
        meta
      }) => (
        <form onSubmit={handleSubmit}>
          {submitFailed && (error || submitError) ? (
            <div className="input__error">{error || submitError}</div>
          ) : null}
          {isFunction(props.children)
            ? props.children({ ...form })
            : props.children}
        </form>
      )}
    />
  );
}

FormForAPI.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired
};

export default FormForAPI;
