import React, { useState } from "react";
import classNames from 'classnames';

import Modal from "./../ui/Modal/Modal";

import classes from './LaunchModal.module.css';

const LaunchModal = ({ onClose, open, url, dashboardLink }) => {
  const [campaignActive, setCampaignActive] = useState(false);

  const setCpActive = () => {
    console.log('setting to active');
    setCampaignActive(true);
  };

  return (
    <Modal modalClose={onClose} show={open}>
      {!campaignActive && <AcceptTermsContent setCampaignStatusToActive={setCpActive}/>}
      {campaignActive && <SuccessContent url={url} dashboardLink={dashboardLink}/>}
    </Modal>
  );
};

export default LaunchModal;


const SuccessContent = ({ url, dashboardLink, }) => {
  const [clicked, setClicked] = useState(false);

  return (<>
    <span className={classes.Heading}>Voucher Campaign is Live!</span>

    <span className={classes.SubHeading}>Invite Customers</span>
    <section className={classes.TextFieldWrapper}>
      <input className={classes.TextField} value={url} readOnly={true}/>
      <label
        className={classNames(classes.CopyLabel, { [classes.CopyLabelClicked]: clicked })}
        onClick={() => {
          setClicked(true);
          copyToClipboard(url)
        }}
      >
        Copy
      </label>
    </section>
    <a href={dashboardLink} className={classes.VisitDashboardLink}>Visit Dashboard</a>
  </>)
};

const AcceptTermsContent = ({ setCampaignStatusToActive }) => {
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [emailAccepted, setEmailAccepted] = useState(false);
  const [errored, setErrored] = useState(false);

  const clickHandler = () => {
    console.log(termsAccepted);
    console.log(emailAccepted);
    if (!termsAccepted || !emailAccepted) {
      setErrored(true);
      return;
    }
    console.log('both accepted');
    // make api call here and when it succeeds call setCampaignStatusToActive
    setCampaignStatusToActive(true);
  };

  return (<div className={classes.TermsWrapper}>
    <span className={classes.Heading}>Payment has been connected!</span>

    <span className={classes.SubHeading}>Please accept the terms of use and data processing agreement</span>
    <label className={classes.TermsLabel}>
      <input
        className={classes.TermsInput}
        type='checkbox'
        value={termsAccepted}
        onChange={() => setTermsAccepted(!termsAccepted)}
      />
      I agree to receive emails about this offer from Vouchery. Check {" "}
      <a
        rel="noopener noreferrer"
        target='_blank'
        href='https://www.vouchery.io/privacy'
      >
        Privacy Policy
      </a>
      for details.
    </label>
    <label className={classes.TermsLabel}>
      <input
        className={classes.TermsInput}
        type='checkbox'
        value={emailAccepted}
        onChange={() => setEmailAccepted(!emailAccepted)}
      />
      I accept the
      <a
        rel="noopener noreferrer"
        target='_blank'
        href='https://www.vouchery.io/termsofuse'
      >
        Terms of use
      </a>
    </label>
    {errored && <span className={classes.ErrorMessage}>Please Accept all Terms before proceeding</span>}
    <button
      className={classNames("button", classes.Button)}
      onClick={clickHandler}
    >
      Activate Campaign
    </button>
  </div>)
};

const copyToClipboard = str => {
  const el = document.createElement('textarea');
  el.value = str;
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
};
