import React from "react";

export default function HowItWorks(props) {
  return (
    <React.Fragment>
      <h2>How Vouchers Work</h2>
      <ol>
        <li>
          <strong>Support Now</strong>
          <span>
            COVID-19 is hitting our community hard. Support your favorite local
            business by buying vouchers.
          </span>
        </li>
        <li>
          <strong>Enjoy Later</strong>
          <span>
            Buy vouchers today that can be used tomorrow. Once business can open
            their doors safely, you'll have a discounted voucher to use.
          </span>
        </li>
        <li>
          <strong>Stronger Together</strong>
          <span>Now is time to come together and support our community.</span>
        </li>
      </ol>
    </React.Fragment>
  );
}
