import React from "react";
import HowItWorks from "./shared/HowItWorks";
import { FieldArray } from "react-final-form-arrays";
import FieldWithErrors from "../FieldWithErrors";

export default function Sidebar(props) {
  const required = value => (value ? undefined : "Required");

  return (
    <section className="sidebar">
      <HowItWorks />
      <h2>Terms & Conditions</h2>
      Use by{" "}
      <FieldWithErrors
        name="timeframe"
        component="input"
        type="text"
        validate={required}
        placeholder="MM/DD/YY"
      />{" "}
      Max vouchers per customer{" "}
      <FieldWithErrors
        name="maxPerCustomer"
        component="input"
        type="text"
        validate={required}
        placeholder="1"
      />
      <FieldArray
        // validate={requiredArray}
        subscription={{ submitting: true, pristine: true }}
        name="ownTerms"
      >
        {({ fields }) => (
          <div className="input-array">
            {fields.map((name, index) => (
              <div key={name} style={{ display: "flex" }}>
                <FieldWithErrors
                  name={name}
                  component="textarea"
                  rows="3"
                  validate={required}
                />
                <button
                  type="button"
                  onClick={() => fields.remove(index)}
                  className="button--remove"
                >
                  &times;
                </button>
              </div>
            ))}
            {fields.length < 5 ? (
              <button type="button" onClick={() => fields.push(" ")}>
                Add terms
              </button>
            ) : null}
          </div>
        )}
      </FieldArray>
    </section>
  );
}
