import React from 'react';
import {
  Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import './App.scss';
import { EditCampaignContainer, PreviewCampaignContainer } from './campaign'
import CampaignLanding from './campaign/landing'
import history from './history'
import WizardSteps from "./campaign/shared/WizardSteps";
import './i18n';

function uuidv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8); // eslint-disable-line
    return v.toString(16);
  });
}

class App extends React.Component {
  generateUuid() {
    const uuid = uuidv4();
    return uuid;
  }

  render() {
    return (
      <div className="App">
        <Router history={history}>
          <WizardSteps/>
          <Switch>
            <Route exact path="/new-campaign" render={() =>
              <Redirect to={`/new-campaign/${this.generateUuid()}`}/>
            }/>
            <Route exact path={"/new-campaign/:uuid"} component={EditCampaignContainer}/>
            <Route exact path={"/preview/:uuid"} component={PreviewCampaignContainer}/>
            <Route exact path={"/launch/:uuid"} component={(props) =>
              <PreviewCampaignContainer {...props} isLaunchStep={true}/>
            }/>
            <Route path="/:slug" component={CampaignLanding}/>
          </Switch>
        </Router>
      </div>
    );
  }
}

export default App;
